/*
:root {
  // brand colors
  --color-primary: #0f2a42;
  --color-secondary: #245b92;
  --color-accent: #5d98a1;
  --color-light: #eceded;

  // other colors
  --color-error: #ff0000;

  // code block background
  --color-code-block: #ccc;

  --padding-default: 20px;
  --padding-half: 10px;
  --border-radius: 8px;
}
*/

/* Styles generated by SB Admin Pro template */
@import "template-styles.css";

.sidenav i-feather {
  display: flex !important;
  align-items: center !important;
}
